import React from "react"
// import { Link } from "gatsby"
import { ClientFormsQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  HeadingBanner,
  SubscriptionPanel,
  QuickStats,
} from "../../components/shared"
import { IMAGES } from "../../constants"
import { trans } from "../../constants"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    main_buttons,
    client_forms,
    page_heading,
    stats_heading,
    stats_items,
  } = ClientFormsQuery(language)
  return (
    <>
      <Layout>
        <HeadingBanner
          heading={page_heading}
          banner_image={banner_image}
          buttons={main_buttons}
        />

        <QuickStats heading={stats_heading} items={stats_items} />

        <section className="job-contents p-top-20 p-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>{page_heading[0].text}</h3>
                <div className="table-responsive table-bordered">
                  <table className="table table-one">
                    <thead className="table-secondary table-secondary--darken ">
                      <tr>
                        <th scope="col">
                          {trans("opening_account", language)}
                        </th>
                        <td  align="center">
                          {trans("download", language)}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {client_forms.map((item, index) => (
                        <tr key={'sdfsdfsdf'+index}>
                          <th scope="row">{item.document_title[0].text}</th>
                          <td align="center">
                            <a href={item.document_link.url} target="_blank" rel="noreferrer">
                              <img src={IMAGES.PDF_FILE_ICON} alt="ddd"/>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
